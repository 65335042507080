import './src/styles/global.scss';
import './src/styles/fonts.scss';
import { wrapPageElement } from './wrapPageElement';
import { RouteUpdateArgs } from 'gatsby';

// 'debounce': set a polling delay for logic that responds to scroll events
const interval = 100;
const scrollThreshold = 50;

let timer; // used to apply debounce
let pageViewCount = 1; // keep track of page views
let scrollListenerAdded = false; // ensure we addEventListener once
let scrollThresholdPassed = false; // keep track of the scroll condition

const pushDataLayerEvent = (eventName: string) => {
  const data = (window as any).dataLayer;
  data &&
    data.push({
      event: eventName,
    });
};

function getScrollPercent() {
  var h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight';
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
}

const handleScroll = (event) => {
  if (timer) {
    window.clearTimeout(timer);
  }

  timer = window.setTimeout(function () {
    const scrollPercent = getScrollPercent();
    if (scrollPercent > scrollThreshold) {
      scrollThresholdPassed = true;
      removeEventListener('scroll', handleScroll);
    }
  }, interval);
};

export const onInitialClientRender = () => {
  if (!scrollListenerAdded) {
    scrollListenerAdded = true;
    addEventListener('scroll', handleScroll);
  }
};

export const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs) => {
  if (process.env.GATSBY_GTM === `true`) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      pushDataLayerEvent(`gatsby-route-change`);
    }, 50);
  }

  // ga is unavailable if appropriate cookies not accepted
  if (
    process.env.GATSBY_GTM !== `true`
  ) {
    return null;
  }

  const sendPageView = () => {
    pageViewCount++;

    if (scrollThresholdPassed && pageViewCount > 2) {
      pushDataLayerEvent('deep-engagement');
    }

    if (typeof (window as any).ga === `function`) {
      (window as any).ga('send', 'pageview', {
        netlify_ab: process.env.GATSBY_BRANCH,
      });
    }

    if (typeof (window as any).gtag === `function`) {
      (window as any).gtag('set', 'user_properties', {
        netlify_branch: process.env.GATSBY_BRANCH,
      });
    }

    if( typeof (window as any).clarity !== 'undefined' ){
      (window as any).clarity("set", "experiment", `netlify_ab_${process.env.GATSBY_BRANCH}` );
    }
  };

  const delayOnRouteUpdate = 0;

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, delayOnRouteUpdate));
    });
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32 + delayOnRouteUpdate);
  }

  return null;
};

export { wrapPageElement };

export const shouldUpdateScroll = ({ routerProps }) => {
  if (!routerProps.location.state) {
    return true;
  }
  const { disableScrollUpdate } = routerProps.location.state;
  return !disableScrollUpdate;
};
