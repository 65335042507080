import React from 'react';
import { Script } from 'gatsby';
import type { GatsbySSR } from 'gatsby';

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({
  element,
  props,
}) => {
  if (props.path.includes('cms-preview')) {
    return <>{element}</>;
  }
  if (element.props.locale && typeof window !== 'undefined') {
    window.localStorage.setItem('gatsby-intl-language', element.props.locale);
  }
  return (
    <>
      {process.env.GATSBY_GTM === `true` && (
        <>
          <Script id="gtm" strategy="idle">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-593GQ2S');`}
          </Script>
        </>
      )}
      {element}
    </>
  );
};
